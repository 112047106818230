import React, { useEffect, useState } from 'react'
import Keyboard from 'react-simple-keyboard'
import Draggable from 'react-draggable'
import 'react-simple-keyboard/build/css/index.css'
import './Keyboard.theme.css'
import { Colors } from '../../Utils/theme';
import DragIcon from '../../Assets/Icons/drag.svg'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next';

const KEYBOARD_LAYOUT = {
  layout: {
    default: [
      "q w e r t y u i o p å",
      "a s d f g h j k l ö ä",
      "{shift} z x c v b n m {backspace}",
      "{numbers} {space} {ent}"
    ],
    shift: [
      "Q W E R T Y U I O P Å",
      "A S D F G H J K L Ö Ä",
      "{shift} Z X C V B N M {backspace}",
      "{numbers} {space} {ent}"
    ],
    numbers: [
      "1 2 3 4 5 6 7 8 9 0",
      '- / : ; ( ) € & @ "',
      "{shift} . , ? ! ´ {backspace}",
      "{abc} {space} {ent}"
    ],
    special: [
      "[ ] { } # % ^ * + =",
      "_ \ | ~ < > $ £ ¥ •",
      "{shift} . , ? ! ´ {backspace}",
      "{abc} {space} {ent}"
    ]
  }
}

const useStyles = makeStyles((theme) => ({
  keyboardContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(67.5rem - 4rem)',
    overflowX: 'hidden',
    position: 'fixed',
    zIndex: 99
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left:0,
    right: 0,
    bottom: 0,
    background: 'transparent',
    zIndex: 1
  },
  handleArea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '4rem',
    backgroundColor: Colors.appGreyLight
  },
  dragContainer: {
  },
  dragIcon: {
    height: '2rem'
  }
}))

function CustomKeyboard(props) {
  const classes = useStyles()
  const { t } = useTranslation()

  const [layoutName, setLayoutName] = useState('default')
  const [keyboard, setKeyboard] = useState(null)

  useEffect(() => {
    if (props.open && keyboard) {
      keyboard.setInput(props.input)
    }
  }, [props.open, keyboard])

  const handleShift = () => {
    if (layoutName === 'default') {
      setLayoutName('shift')
    }
    if (layoutName === 'shift') {
      setLayoutName('default')
    }
    if (layoutName === 'numbers') {
      setLayoutName('special')
    }
    if (layoutName === 'special') {
      setLayoutName('numbers')
    }
  }

  const handeNumbers = () => {
    setLayoutName('numbers')
  }

  const handleAbc = () => {
    setLayoutName('default')
  }

  const onKeyPress = (button) => {
    // console.log("Button pressed", button);
    if (button === '{shift}' || button === '{lock}') handleShift()
    if (button === '{numbers}') handeNumbers()
    if (button === '{abc}') handleAbc()
    if (button === '{ent}') props.onClose()
  }

  const getKeyboardDisplay = () => {
    return {
      "{numbers}": "123",
      "{escape}": "esc ⎋",
      "{tab}": "tab ⇥",
      "{backspace}": "⌫",
      "{capslock}": "caps lock ⇪",
      "{shift}": "⇧",
      "{controlleft}": "ctrl ⌃",
      "{controlright}": "ctrl ⌃",
      "{altleft}": "alt ⌥",
      "{altright}": "alt ⌥",
      "{metaleft}": "cmd ⌘",
      "{metaright}": "cmd ⌘",
      "{abc}": "ABC",
      "{space}": " ",
      "{ent}": t('done')
    }
  }

  const renderKeyboard = () => {
    if (props.open) {
      return (
        <>
          <div
            className={classes.overlay}
            onClick={props.onClose}
          />
          <Draggable
            axis='y'
            bounds='body'
            handle='strong'
            defaultPosition={{ x: 0, y: Math.floor(window.innerHeight * 0.33) - (props.adjustUp || 0) }}
          >
            <div className={classes.keyboardContainer}>
              <Keyboard
                keyboardRef={(r) => setKeyboard(r)}
                layoutName={layoutName}
                onChange={props.onChange}
                onKeyPress={onKeyPress}
                theme={"hg-theme-default hg-theme-ios"}
                {...KEYBOARD_LAYOUT}
                display={getKeyboardDisplay()}
              />
              <strong
                className={classes.handleArea}
              >
                <div className={classes.dragContainer}>
                  <img src={DragIcon} className={classes.dragIcon} />
                </div>
              </strong>
            </div>
          </Draggable>
        </>
      )
    }
    return null
  }

  return (
    <div style={{ color: Colors.black }}>
      {props.children}
      {renderKeyboard()}
    </div>
  )

}

export default CustomKeyboard
